import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  layout: {
    "& > section": {
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.white.main,
      borderRadius: theme.spacing(1),
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      "& label": {
        fontSize: 18,
        backgroundColor: "white",
      },
      "& > div": {
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(2),
        "& > div:not([role='radiogroup']):not([class*='fullWidth'])": {
          width: theme.spacing(35),
        },
        "& div + div": {
          marginLeft: theme.spacing(5),
        },
      },
      "& > div:last-of-type": {
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(2),

        "& button": {
          borderRadius: theme.spacing(3),
        },
      },
    },
  },
}));
