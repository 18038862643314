import React, { ChangeEvent, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Souscription } from "@/types/Souscription";
import TextField from "@/components/TextField";
import { PnoSouscriptionContexte } from "views/PnoSouscription";
import { Select } from "views/PnoSouscription/FormulaireElements/Select";
import { Phone } from "views/PnoSouscription/Etapes/InfosPerso/Phone";
import { DatePicker } from "views/PnoSouscription/FormulaireElements/DatePicker";
import { Champ } from "views/PnoSouscription/FormulaireElements/Champ";
import { Radio } from "views/PnoSouscription/FormulaireElements/Radio";
import { Upload } from "views/PnoSouscription/Etapes/InfosPerso/Upload";
import { PJ } from "views/PnoSouscription/Etapes/InfosPerso";
import { capitalizeFirstLetter } from "@/utils/string";
import { PaysContext } from "@/contexts/pays";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type Contact = getTypeOriginalDepuisPartial<Souscription["data"]["contact"]>;

interface InfosPersoFormProps {
  affichePopin: () => void;
  pj: PJ | null;
  setPj: React.Dispatch<React.SetStateAction<PJ | null>>;
}

export const InfosPersoForm = ({
  pj,
  setPj,
  affichePopin,
}: InfosPersoFormProps) => {
  const { dataSouscription, setDataSouscription } = useContext(
    PnoSouscriptionContexte
  );
  const { pays } = useContext(PaysContext);
  const { t } = useTranslation();
  const data = dataSouscription.data?.contact;

  const onChange = (e: ChangeEvent<HTMLInputElement>, type?: keyof Contact) => {
    let valeurSaisie = e.target.value;

    if (type === "lastName") valeurSaisie = valeurSaisie.toUpperCase();
    if (type && ["firstName", "birthCity"].includes(type))
      valeurSaisie = capitalizeFirstLetter(valeurSaisie);

    let oldData = { ...data };
    let newData = { ...oldData, [type as keyof Contact]: valeurSaisie };

    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        contact: newData as Contact,
      },
    });
  };

  const dateDeNaissanceMinimale = new Date();
  dateDeNaissanceMinimale.setFullYear(new Date().getFullYear() - 100);

  return (
    <>
      <div>
        <Radio
          value={data?.gender || ""}
          items={[
            { label: t("pno.monsieur"), value: "M." },
            { label: t("pno.madame"), value: "MME" },
          ]}
          type={"gender"}
          onChange={onChange}
        />
        <TextField
          value={dataSouscription.data?.numFoncia || ""}
          label={t("pno.numFoncia")}
          onClick={affichePopin}
          style={{ marginLeft: "auto" }}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          required
        />
      </div>
      <div>
        <Champ
          label={t("pno.nom")}
          type={"lastName"}
          value={data?.lastName || ""}
          onChange={onChange}
          maxLength={100}
        />
        <Champ
          label={t("pno.prenom")}
          type={"firstName"}
          value={data?.firstName || ""}
          onChange={onChange}
          maxLength={100}
        />
      </div>
      <div>
        <TextField
          label={t("pno.nonNaissance")}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 100 }}
        />
      </div>
      <div>
        <Champ
          label={t("pno.email")}
          type={"mail"}
          value={data?.mail || ""}
          onChange={onChange}
          dataType={"email"}
          maxLength={100}
        />
        <Phone
          label={t("pno.telephone")}
          valeur={{
            numero: data?.phone?.value || "",
            pays: data?.phone?.country?.countryCode || "",
          }}
          data={data}
          dataSouscription={dataSouscription}
          setDataSouscription={setDataSouscription}
        />
      </div>
      <div>
        <Champ
          label={t("pno.villeNaissance")}
          type={"birthCity"}
          value={data?.birthCity || ""}
          onChange={onChange}
        />
        <Select
          label={t("pno.paysNaissance")}
          items={pays}
          value={data?.countryBirth || ""}
          type={"countryBirth"}
          onChange={onChange}
        />
        <DatePicker
          label={t("pno.dateNaissance")}
          type={"birthdate"}
          value={data?.birthdate || ""}
          onChange={onChange}
          minDate={dateDeNaissanceMinimale}
          maxDate={new Date()}
        />
      </div>
      <div>
        <Select
          label={t("pno.typeJustificatif")}
          items={[
            { label: t("pno.passport"), value: "P" },
            { label: t("pno.carteIdentite"), value: "CIN" },
          ]}
          value={pj?.type}
          onChange={(e) => setPj({ ...pj, type: e.target.value })}
        />
        <Upload
          label={t("pno.justificatifIdentite")}
          valeur={pj?.valeur}
          onChange={(file: File) => setPj({ ...pj, valeur: file })}
          supprimerFichier={() => setPj({ ...pj, valeur: null })}
        />
      </div>
    </>
  );
};
