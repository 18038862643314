import React from "react";
import MuiPhoneNumber from "material-ui-phone-number";

import { Souscription } from "@/types/Souscription";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type Contact = getTypeOriginalDepuisPartial<Souscription["data"]["contact"]>;

type PhoneType = Contact["phone"];
interface PhoneProps {
  label: string;
  valeur?: {
    numero?: string;
    pays?: string;
  };
  data: Contact | undefined;
  dataSouscription: Partial<Souscription>;
  setDataSouscription: (data: Partial<Souscription>) => void;
  required?: boolean;
}

export const Phone = ({
  label,
  valeur,
  data,
  dataSouscription,
  setDataSouscription,
  required = true,
}: PhoneProps) => {
  const onChange = (numero: string, pays: PhoneType["country"]) => {
    let value = numero;

    if (pays.countryCode === "fr") {
      value = numero.replaceAll(" ", "").replace("+330", "+33");
    }
    let oldData = { ...data };
    let newData = { ...oldData, phone: { value: value, country: pays } };

    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        contact: newData as Contact,
      },
    });
  };

  return (
    <MuiPhoneNumber
      label={label}
      defaultCountry={valeur?.pays || "fr"}
      value={valeur?.numero}
      onChange={onChange}
      variant="outlined"
      required={required}
      disableAreaCodes
    />
  );
};
