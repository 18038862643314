import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography, Box } from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

import useStyles from "./style";
import { URLS } from "@/constants/urls";
import { getParentUrl, items } from "@/components/Sidebar/items";
import { useTranslation } from "react-i18next";

interface DefaultBreadcrumbProps {
  title: string;
  description?: string;
}

export const Breadcrumb = ({ title, description }: DefaultBreadcrumbProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const onBackButtonClick = () => {
    const url = location.pathname;

    const urlDuMenuParent = getParentUrl(url, items(t));

    return !!description && urlDuMenuParent
      ? () => navigate(urlDuMenuParent)
      : () => navigate(URLS.BOARD);
  };

  return (
    <Box className={classes.box}>
      <KeyboardBackspaceIcon
        classes={{ root: classes.icon }}
        onClick={onBackButtonClick()}
      />
      <Typography variant="h6">{title}</Typography>

      {description && (
        <>
          <ArrowForwardIosIcon classes={{ root: classes.secondIcon }} />
          <Typography variant="body1" className={classes.subtitle}>
            {description}
          </Typography>
        </>
      )}
    </Box>
  );
};
