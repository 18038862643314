import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import TablePagination from "@material-ui/core/TablePagination";
import { useSnackbar } from "notistack";

import {
  FilterPayloadRecord,
  PageableReponse,
  PayloadWithPageAndFilter,
  SortPayload,
} from "@/types/ApiResponse";
import StaticTable, { DataStructure } from "@/components/StaticTable";

export interface Pageable {
  pageSize: number;
  pageNumber: number;
}
interface ApiTablePagineProps<T> {
  getData: (
    filters: any,
    sort: SortPayload,
    currentPage: number,
    size: number
  ) => Promise<AxiosResponse<PageableReponse<T>>>;
  dataStructure: DataStructure<T>[];
  rowIdentifier: keyof T;
}

export function ApiTablePagine<T>(props: ApiTablePagineProps<T>) {
  const DEFAULT_ELEMENTS_PER_PAGE = 10;

  const { dataStructure, getData, rowIdentifier } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [responsePageable, setReponsePageable] =
    useState<PageableReponse<T> | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [filters, setFilters] = useState({});
  const [sort, setSort] = useState<SortPayload | null>(null);

  const data: T[] = responsePageable?.content || [];

  const fetchData = async (payload: PayloadWithPageAndFilter) => {
    try {
      setLoading(true);
      const response = (await getData(
        payload.filter,
        payload.sort,
        payload.page,
        payload.size
      )) as unknown as PageableReponse<T>;
      setReponsePageable(response);
    } catch (err: any) {
      enqueueSnackbar(err, {
        variant: "error",
      });
      return null;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({
      filter: filters,
      sort: sort as SortPayload,
      page: 0,
      size: DEFAULT_ELEMENTS_PER_PAGE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCurrentPageChange = (event: unknown, newPage: number) => {
    fetchData({
      filter: filters,
      sort: sort as SortPayload,
      page: newPage,
      size: responsePageable?.pageable.pageSize as number,
    });
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    fetchData({
      filter: filters,
      sort: sort as SortPayload,
      page: responsePageable?.pageable.pageNumber as number,
      size: newRowsPerPage,
    });
  };

  const onValidateFilter = (newFilter: FilterPayloadRecord) => {
    fetchData({
      filter: newFilter,
      sort: sort as SortPayload,
      page: 0,
      size: responsePageable?.pageable.pageSize as number,
    });

    setFilters(newFilter);
  };

  const onSort = (newSort: SortPayload) => {
    fetchData({
      filter: filters,
      sort: newSort,
      page: 0,
      size: responsePageable?.pageable.pageSize as number,
    });
    setSort(newSort);
  };

  return (
    <StaticTable
      data={data}
      dataStructure={dataStructure}
      identifyRow={(row) => row[rowIdentifier]}
      onValidateFilter={onValidateFilter}
      onSort={onSort}
      sort={sort as SortPayload}
      loading={loading}
      renderFooter={() =>
        responsePageable ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            // colSpan={dataStructure.length}
            count={responsePageable.totalElements}
            rowsPerPage={responsePageable.pageable.pageSize}
            page={responsePageable.pageable.pageNumber}
            onPageChange={handleCurrentPageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage={t("common.dmri.paginate.labelRowsPerPage")}
          />
        ) : (
          <></>
        )
      }
    />
  );
}
