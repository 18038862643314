import React, {
  createContext,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { generateUUID } from "@/utils/uuid";
import { Souscription } from "@/types/Souscription";
import souscriptionApi from "@/api/souscriptionApi";

import { Header } from "./Header";
import { InfosPerso } from "./Etapes/InfosPerso";
import { Adresse } from "./Etapes/Adresse";
import { TypeBien } from "./Etapes/TypeBien";
import { DateEffet } from "./Etapes/DateEffet";
import { Recapitulatif } from "./Etapes/Recapitulatif";
import { Signature } from "./Etapes/Signature";
import { useStyles } from "./style";

export const EtapeObj = {
  /* ATTETION, L'ORDRE DE L'OBJET DEFINIT L'ORDRE DES ECRANS DE SOUSCRIPTION */
  INFOS_PERSO: <InfosPerso />,
  ADRESSE: <Adresse />,
  TYPE_DU_BIEN: <TypeBien />,
  DATE_EFFET: <DateEffet />,
  RECAPITULATIF: <Recapitulatif />,
  SIGNATURE: <Signature />,
} as const;

export type Etape = keyof typeof EtapeObj;
const etapeList: Etape[] = Object.keys(EtapeObj) as Etape[];

export const PnoSouscriptionContexte = createContext<{
  dataSouscription: Partial<Souscription>;
  setDataSouscription: (data: Partial<Souscription>) => void;
  retourEtapePrecedente: () => void;
  formRef: React.RefObject<HTMLFormElement> | null;
}>({
  dataSouscription: {},
  setDataSouscription: () => null,
  retourEtapePrecedente: () => null,
  formRef: null,
});

const PnoSouscription = () => {
  const [etape, setEtape] = useState<Etape>(etapeList[0]);
  const [dataSouscription, setDataSouscription] = useState<
    Partial<Souscription>
  >({ skipSteps: false, visitedSteps: [] });
  const defaultParcoursIdRef = useRef(generateUUID());
  const formRef = useRef<HTMLFormElement>(null);
  const classes = useStyles();

  useEffect(() => {
    if (
      dataSouscription.data?.contractAddress &&
      dataSouscription.data.contact
    ) {
      souscriptionApi.persisteInformationsSouscriptionPNO(
        dataSouscription,
        defaultParcoursIdRef.current
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSouscription.currentStep]);

  const majSouscriptionData = (
    indexEtapeCourante: number,
    newData: Partial<Souscription>
  ) => {
    const nomDeLEtape = etapeList[indexEtapeCourante];
    const visitedStepsActuel = dataSouscription.visitedSteps || [];
    const visitedSteps = [...visitedStepsActuel, nomDeLEtape];

    setDataSouscription({
      ...dataSouscription,
      visitedSteps: visitedSteps,
      currentStep: {
        group: 0,
        step: indexEtapeCourante,
      },
      data: {
        ...dataSouscription.data!,
        ...newData.data,
      },
    });
  };

  const valideEtapeCourante = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const indexEtapeCourante = etapeList.indexOf(etape);

    setEtape(etapeList[indexEtapeCourante + 1]);
    majSouscriptionData(indexEtapeCourante, dataSouscription);
  };

  const retourEtapePrecedente = () => {
    var indexEtapeCourante = etapeList.indexOf(etape);
    setEtape(etapeList[indexEtapeCourante - 1]);
  };

  return (
    <PnoSouscriptionContexte.Provider
      value={{
        dataSouscription,
        setDataSouscription,
        retourEtapePrecedente,
        formRef,
      }}
    >
      <Header />
      <form
        ref={formRef}
        onSubmit={valideEtapeCourante}
        className={classes.layout}
      >
        {EtapeObj[etape]}
      </form>
    </PnoSouscriptionContexte.Provider>
  );
};

export default PnoSouscription;
