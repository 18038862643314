import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import PnoSvg from "assets/images/pno.svg";

import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import { BoxRecap } from "@/views/PnoSouscription/BoxRecap";
import { Grid } from "@material-ui/core";

export const Signature = () => {
  const { dataSouscription } = useContext(PnoSouscriptionContexte);
  const data = dataSouscription.data?.contact;
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6">{t("pno.titrePageSignature")}</Typography>

      <section style={{ display: "flex", alignItems: "center" }}>
      <Grid>
        <img src={PnoSvg} alt="Contrat PNO" />
      </Grid>
      <Grid item md={12}>
        <Typography style={{ marginLeft: "20px" }}>
          {t("pno.confirmationEnvoiEmailSignature")}
        </Typography>
      </Grid>
      <Grid item md={2}>
        <BoxRecap
          titre={t("pno.coordonéesSignature")}
          sousTitre1={data?.mail}
          sousTitre2={data?.phone.value}
        />
      </Grid>
      </section>
    </>
  );
};
