import axiosClient from "@/api/axiosClient";
import { Souscription } from "@/types/Souscription";

const API_BASE_URL = "/v3/souscription";

const souscriptionApi = {
  async persisteInformationsSouscriptionPNO(
    parcoursJson: Partial<Souscription>,
    idParcours: string
  ) {
    const url = `${API_BASE_URL}/pno/${idParcours}`;

    return await axiosClient.post(url, parcoursJson);
  },

  async recupereInformationsSouscriptionPNO(idParcours: string) {
    let url = `${API_BASE_URL}/pno/${idParcours}`;

    return await axiosClient.get(url);
  },
};

export default souscriptionApi;
