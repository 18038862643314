import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";

import TextField from "@/components/TextField";
import customerApi from "@/api/customerApi";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import { useStyles } from "./style";
import { Souscription } from "@/types/Souscription";
import { useTranslation } from "react-i18next";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type Contact = getTypeOriginalDepuisPartial<Souscription["data"]["contact"]>;
interface PopinRechercheParNumFonciaProps {
  onClose: () => void;
}
export const RechercheProrio = ({
  onClose,
}: PopinRechercheParNumFonciaProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { dataSouscription, setDataSouscription } = useContext(
    PnoSouscriptionContexte
  );
  const [numProprietaireSearch, setNumProprietaireSearch] = useState<
    number | null
  >(null);
  const [etatRecherche, setEtatRecherche] = useState<
    "attente" | "loading" | "erreur"
  >("attente");

  const rechercherProprietaire = async (numeroProprietaire: number | null) => {
    if (numeroProprietaire == null) return;
    try {
      setEtatRecherche("loading");
      const resultatRechercheProprio = await customerApi.getInfoProprietaire(
        numeroProprietaire
      );

      const prorio = {
        gender: resultatRechercheProprio.gender || "",
        lastName: resultatRechercheProprio.lastName || "",
        firstName: resultatRechercheProprio.firstName || "",
        mail: resultatRechercheProprio.mail || "",
        phone: { value: resultatRechercheProprio.phone || "" },
        birthdate: resultatRechercheProprio.birthdate || "",
        //birthCity: "paris",
        //countryBirth: "France",
      };

      if (resultatRechercheProprio) {
        onClose();
        setDataSouscription({
          ...dataSouscription,
          data: {
            ...dataSouscription.data,
            numFoncia: numProprietaireSearch?.toString(),
            contact: prorio as Contact,
          },
        });
      } else {
        setEtatRecherche("erreur");
      }
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 404) setEtatRecherche("erreur");
      else {
        console.error("Une erreur est survenue: ", error);
        enqueueSnackbar(
          "Une erreur est survenue lors de la récupération du propriétaire Foncia, veuillez réessayer ultérieurement",
          {
            variant: "error",
          }
        );
      }
    }
  };

  const creationNouveauProprio = () => {
    onClose();
    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        numFoncia: numProprietaireSearch?.toString(),
      },
    });
  };

  useEffect(() => {
    setEtatRecherche("attente");
  }, [numProprietaireSearch]);

  return (
    <Box className={classes.boxWrapper}>
      <Typography variant="h6">{t("pno.jaiNumFoncia")}</Typography>
      <Box className={classes.boxTextField}>
        <Grid container>
          <Grid item xs={10}>
            <TextField
              label={t("pno.numFoncia")}
              type="number"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                return setNumProprietaireSearch(parseInt(e.target.value));
              }}
              className={classes.input}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => rechercherProprietaire(numProprietaireSearch)}
              disabled={etatRecherche !== "attente"}
            >
              {t("pno.suivant")}
            </Button>
          </Grid>
          {etatRecherche === "erreur" && (
            <Grid item xs={12}>
              <Typography className="errorMsg">
                {t("pno.aucunProprioTrouve")}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>

      <Typography variant="body1" align="center">
        {t("pno.ou")}
      </Typography>

      <Box className={classes.boxFooter}>
        <Typography variant="h6">{t("pno.jaiPasNumFoncia")}</Typography>
        <Box className="boxButton">
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={creationNouveauProprio}
          >
            {t("pno.nouveauProprio")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
