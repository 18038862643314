import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import { Souscription } from "@/types/Souscription";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import { DatePicker } from "views/PnoSouscription/FormulaireElements/DatePicker";
import { BoutonRetour } from "@/views/PnoSouscription/BoutonRetour";
import { BoutonSuivant } from "@/views/PnoSouscription/BoutonSuivant";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type DataSouscription = getTypeOriginalDepuisPartial<Souscription["data"]>;

export const DateEffet = () => {
  const { dataSouscription, setDataSouscription } = useContext(
    PnoSouscriptionContexte
  );
  const [dateDebutErronee, setDateDebutErronnee] = useState(false);
  const { t } = useTranslation();
  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    type: keyof DataSouscription
  ) => {
    const newData = e.target.value;
    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        [type]: newData,
      },
    });
  };

  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 1);

  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 3);

  useEffect(() => {
    if (
      dataSouscription.data?.contractStartDate &&
      new Date(dataSouscription.data?.contractStartDate) > maxDate
    ) {
      setDateDebutErronnee(true);
    } else setDateDebutErronnee(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSouscription.data?.contractStartDate]);

  return (
    <>
      <Typography variant="h6">{t("pno.dateEffet_title")}</Typography>
      <section>
        <Grid>
          <Grid item md={5}>
            <br />
            <Typography>{t("pno.dateDebutContrat")} :</Typography>
          </Grid>
          <Grid item md={12}>
            <DatePicker
              value={dataSouscription.data?.contractStartDate || ""}
              label={t("pno.dateEffet")}
              type={"contractStartDate"}
              onChange={onChange}
              minDate={minDate}
              erreur={
                dateDebutErronee
                  ? "Revenez nous voir dans 3 mois, nous serons contents de vous revoir"
                  : null
              }
            />
          </Grid>
          <div
            style={{
              padding: 10,
              borderRadius: 10,
              backgroundColor: "#FF9100",
              width: "400px",
              color: "white",
            }}
          >
            <Typography style={{ color: "white" }} variant="body1">
              {t("pno.bienAssure")}
            </Typography>
          </div>
        </Grid>
        <div>
          <BoutonRetour />
          <BoutonSuivant estDesactive={dateDebutErronee} />
        </div>
      </section>
    </>
  );
};
