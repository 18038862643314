export enum URLS {
  BOARD = "/",
  FORBIDDEN = "/forbiddenAccess",
  TERM_POLICIES = "/termpolicies",
  MRI = "/mri",
  MRI_DMRI = "/mri/dmri",
  MRI_DMRI_ID = `/mri/dmri/:id`,
  MRI_QUOTE = "/mri/quotation",
  PNO = "/pno",
  PNO_CONTRACTS = "/pno/contracts",
  PNO_SOUSCRIPTION = "/pno/souscription",
  PNO_CLAIMS = "/pno/claims",
  PNO_PREMIUMS = "/pno/premiums",
  HOME_INSURANCE = "/homeInsurance",
  PJ = "/pj",
  DO = "/do",
  CLAIMS = "/claims",
  CONTRATS = "/contrats",
}

export type TypeURL = URLS;
