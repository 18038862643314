import React, { useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Button from "@material-ui/core/Button";

import PnoSvg from "assets/images/pno.svg";
import { useStyles } from "./style";
import { UserContext } from "@/contexts/user";
import { Etape, PnoSouscriptionContexte } from "@/views/PnoSouscription";

export const Header = () => {
  // @ts-ignore
  const [userState] = useContext(UserContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const { dataSouscription } = useContext(PnoSouscriptionContexte);
  const downloadLink = useRef(null);

  const cabinet = userState?.profile?.code || "-";
  const nomComplet =
    dataSouscription.data?.contact?.firstName +
    " " +
    dataSouscription.data?.contact?.lastName;

  const etapeInfoPerso: Etape = "INFOS_PERSO";
  const pourLeCompteDuClient = dataSouscription.visitedSteps?.includes(
    etapeInfoPerso
  )
    ? t("pno.pourLeCompte", { nomComplet: nomComplet })
    : null;

  const etapeTypeBien: Etape = "TYPE_DU_BIEN";
  const pourLeLot =
    dataSouscription.visitedSteps?.includes(etapeTypeBien) &&
    dataSouscription.data?.contractAddress?.lotNumber
      ? t("pno.pourLeLot", {
          numLot: dataSouscription.data?.contractAddress?.lotNumber,
        })
      : null;
  const loadDetailsGaranties = () => {
    //@ts-ignore
    if (downloadLink.current) downloadLink.current.click();
  };

  return (
    <div className={classes.headerLayout}>
      <img src={PnoSvg} alt="Contrat PNO" />
      <div>
        <Typography variant="h5">{`${t("pno.souscriptionTitle")}`}</Typography>
        <Typography variant="body1">
          {t("pno.cabinet")} {cabinet} {pourLeCompteDuClient}
        </Typography>
        <Typography variant="body1">{pourLeLot}</Typography>
      </div>
      <Button
        variant="outlined"
        size="large"
        endIcon={<VisibilityIcon />}
        onClick={loadDetailsGaranties}
      >
        {t("pno.detailsGaranties")}
      </Button>
      <a
        href="/files/RESUME_AXA_PNO_2024.pdf"
        ref={downloadLink}
        download
        style={{ display: "none" }}
      >
        Download
      </a>
    </div>
  );
};
