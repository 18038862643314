import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import Popin from "@/components/Popin";
import { PnoSouscriptionContexte } from "views/PnoSouscription";
import { BoutonSuivant } from "views/PnoSouscription/BoutonSuivant";
import { ErreurFinscan } from "views/PnoSouscription/Etapes/InfosPerso/ErreurFinscan";
import { RechercheProrio } from "./RechercheProrio";
import { InfosPersoForm } from "@/views/PnoSouscription/Etapes/InfosPerso/Form";
import customerApi from "@/api/customerApi";
import { Personne } from "@/types/personne";
import { validerNumeroTel } from "@/utils/phone";

export interface PJ {
  type?: string;
  valeur?: File | null;
}

export const InfosPerso = () => {
  const { dataSouscription } = useContext(PnoSouscriptionContexte);
  const { t } = useTranslation();

  const data = dataSouscription.data?.contact;
  const contactInfoVide = () => (data ? Object.keys(data).length === 0 : true);
  const [popinAffichee, setPopinAffichee] = useState<boolean>(
    contactInfoVide()
  );

  const [pj, setPj] = useState<PJ | null>(null);
  const [erreurFinscan, setErreurFinscan] = useState<"ko" | "pending" | null>(
    null
  );

  const onValideInfoPerso: () => Promise<string | null> = () =>
    new Promise(async (resolve, reject) => {
      const numeroTelInvalide = !validerNumeroTel(
        data?.phone.value || "",
        data?.phone?.country?.countryCode || ""
      );
      if (numeroTelInvalide) {
        return reject("Veuiller saisir un numéro valide");
      }

      // call api finscan
      const finscanResponse = "ok";

      if (finscanResponse === "ok") {
        await customerApi
          .enregistreProprio(
            {
              ...data,
              gender: data?.gender,
              numFoncia: dataSouscription?.data?.numFoncia,
              phone: data?.phone?.value,
              typeJustificatifIdentite: pj?.type,
            } as Personne,
            pj?.valeur as File
          )
          .then(() => resolve(null))
          .catch(() =>
            reject(
              "Une erreur est survenue lors de l'enregistrement du proprietaire"
            )
          );
      } else if (finscanResponse === "pending") setErreurFinscan("pending");
      else setErreurFinscan("ko");
      return reject("Une erreur est survenue lors de la vérification Finscan");
    });

  return erreurFinscan === "pending" ? (
    <ErreurFinscan
      erreur={"pending"}
      retourPageInfo={() => setErreurFinscan(null)}
    />
  ) : erreurFinscan === "ko" ? (
    <ErreurFinscan erreur={"ko"} />
  ) : (
    <>
      <Typography variant="h6">{t("pno.infosProprio")}</Typography>
      <section>
        <InfosPersoForm
          pj={pj}
          setPj={setPj}
          affichePopin={() => setPopinAffichee(true)}
        />
        <div>
          <BoutonSuivant onClick={onValideInfoPerso} />
        </div>
      </section>
      <Popin
        title={t("pno.nouveauContrat")}
        content={<RechercheProrio onClose={() => setPopinAffichee(false)} />}
        open={popinAffichee}
        onClose={() => setPopinAffichee(false)}
        maxWidth="sm"
      />
    </>
  );
};
