import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";

import { URLS } from "@/constants/urls";
import { Breadcrumb } from "./Breadcrumb";

export const FilAriane = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const url = location.pathname;
  const mriContractNumber = url.replace(URLS.MRI_DMRI + "/", "");

  switch (url) {
    case URLS.BOARD:
      return (
        <Typography variant="h6">{t("dashboard.menu.dashboard")}</Typography>
      );
    case URLS.MRI_DMRI:
      return <Breadcrumb title={t("common.dmri.multiriskBuildingInsurance")} />;
    case URLS.MRI_DMRI_ID.replace(":id", mriContractNumber):
      const desc = t("common.dmri.contractDetail") + " " + mriContractNumber;
      return (
        <Breadcrumb
          title={t("common.dmri.multiriskBuildingInsurance")}
          description={desc}
        />
      );
    case URLS.MRI_QUOTE:
      return <Breadcrumb title={t("common.dmri.multiriskBuildingInsurance")} />;
    case URLS.PNO_SOUSCRIPTION:
      return (
        <Breadcrumb
          title={t("dashboard.menu.newContract")}
          description={t("dashboard.menu.souscriptionCompte2")}
        />
      );
    case URLS.PNO_CLAIMS:
      return <Breadcrumb title={t("dashboard.menu.ongoingClaims")} />;
    case URLS.PNO_PREMIUMS:
      return <Breadcrumb title={t("dashboard.menu.ongoingPremiums")} />;
    case URLS.HOME_INSURANCE:
      return <Breadcrumb title={t("dashboard.menu.homeInsurancePolicy")} />;
    case URLS.PJ:
      return <Breadcrumb title={t("dashboard.menu.pjContracts")} />;
    case URLS.DO:
      return <Breadcrumb title={t("dashboard.menu.doContracts")} />;
    case URLS.TERM_POLICIES:
      return <Breadcrumb title={t("dashboard.menu.plannedPayments")} />;
    case URLS.CLAIMS:
      return <Breadcrumb title={t("dashboard.menu.claims")} />;
    default:
      return <></>;
  }
};
