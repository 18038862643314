import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import Typography from "@material-ui/core/Typography";
import { useSnackbar } from "notistack";

import { BoxRecap } from "@/views/PnoSouscription/BoxRecap";
import { BoutonRetour } from "@/views/PnoSouscription/BoutonRetour";
import { BoutonSuivant } from "@/views/PnoSouscription/BoutonSuivant";
import { InfoClient } from "@/views/PnoSouscription/Etapes/Recapitulatif/InfoClient";
import { InfoBien } from "@/views/PnoSouscription/Etapes/Recapitulatif/InfoBien";
import { InfoTarif } from "@/views/PnoSouscription/Etapes/Recapitulatif/InfoTarif";
import { BlocTarif } from "@/views/PnoSouscription/Etapes/Recapitulatif/BlocTarif";
import { Confirmations } from "@/views/PnoSouscription/Etapes/Recapitulatif/Confirmations";
import { UserContext } from "@/contexts/user";
import tarifApi from "@/api/tarifApi";
import Loader from "@/components/Loader";
import theme from "@/theme";
import { Tarif } from "@/types/Tarif";

export const Recapitulatif = () => {
  const { dataSouscription } = useContext(PnoSouscriptionContexte);

  // @ts-ignore
  const [userState] = useContext(UserContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [tarifInfo, setTarifInfo] = useState<Tarif | null>(null);
  const [montantPremierPaiement, setMontantPremierPaiement] = useState<
    number | null
  >(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await tarifApi.getTarif(dataSouscription.data || {});
        setTarifInfo(response);
      } catch (err) {
        afficheErreurRechercheTarif();
      }
    };

    if (dataSouscription.data?.contractAddress?.address1) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async (tarif: Tarif) => {
      try {
        const premierPrelevement = await tarifApi.getPremierPrelevement(
          tarif.tarifPropose.proposition.annualTTC,
          new Date(dataSouscription.data!.contractStartDate!)
        );
        setMontantPremierPaiement(
          premierPrelevement.paymentPlan[0].firstPremiumAmountInclTax
        );
      } catch (err) {
        afficheErreurRechercheTarif();
      }
    };

    if (tarifInfo) fetchData(tarifInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tarifInfo]);

  const afficheErreurRechercheTarif = () =>
    enqueueSnackbar("Une erreur est survenue lors de la recherche de tarif", {
      variant: "error",
    });

  const creationPolice: () => Promise<string | null> = () =>
    new Promise(async (resolve) => {
      // TODO: Appeler l'api de creation de police
      resolve(null);
    });

  const montantAnnuelAvecTA =
    tarifInfo?.tarifPropose?.proposition?.annualTtcWithSurtaxe;
  const montantTaxeAttentat =
    tarifInfo?.tarifPropose?.proposition?.annualSurtaxe;

  const loading = !(
    montantAnnuelAvecTA &&
    montantTaxeAttentat &&
    montantPremierPaiement
  );

  return (
    <>
      <Typography variant="h6">Récapitulatif et signature</Typography>
      <section>
        {loading && <Loader />}
        <div>
          <Grid item xs={9} md={9}>
            <InfoClient />
            <br />
            <InfoBien />
            <br />
            {!loading && (
              <InfoTarif
                montantPremierPrelevement={montantPremierPaiement}
                montantTaxeAttentat={montantTaxeAttentat}
              />
            )}
            <br />
            <Confirmations />
          </Grid>
          {!loading && (
            <BlocTarif
              montantAnnuelAvecTA={montantAnnuelAvecTA}
              montantTaxeAttentat={montantTaxeAttentat}
            />
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <BoxRecap
            titre={t("pno.coordonéesSignature")}
            sousTitre1={userState.email || "-"}
            sousTitre2="-"
          />
          <BoxRecap titre={t("pno.coordonéesPrelevement")} />
        </div>
        <div>
          <BoutonRetour />
          <BoutonSuivant onClick={creationPolice} />
        </div>
      </section>
    </>
  );
};

export const TextSurbrillance = (
  <span style={{ color: theme.palette.primary.main, fontWeight: "bold" }} />
);
