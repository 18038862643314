import React, { ChangeEvent, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";

import { Souscription } from "@/types/Souscription";
import { Champ } from "@/views/PnoSouscription/FormulaireElements/Champ";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import { useStyles } from "./style";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type Adresse = getTypeOriginalDepuisPartial<
  Souscription["data"]["contractAddress"]
>;

interface AdresseManuelleProps {
  changerEnModeAuto: () => void;
}

export const AdresseManuelle = ({
  changerEnModeAuto,
}: AdresseManuelleProps) => {
  const classes = useStyles();
  const { dataSouscription, setDataSouscription } = useContext(
    PnoSouscriptionContexte
  );

  const { t } = useTranslation();

  const adresse: Partial<Adresse> =
    dataSouscription.data?.contractAddress || {};

  const onChange = (e: ChangeEvent<HTMLInputElement>, type: keyof Adresse) => {
    let oldData = { ...adresse };
    let newData = { ...oldData, [type]: e.target.value };

    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        contractAddress: newData as Adresse,
      },
    });
  };

  return (
    <section className={classes.layout}>
      <Champ
        value={adresse.address1}
        label={t("pno.adresse")}
        type={"address1"}
        onChange={onChange}
      />
      <Champ
        value={adresse.address2}
        label={t("pno.complement")}
        type={"address2"}
        onChange={onChange}
        required={false}
      />
      <Champ
        value={adresse.zipCode}
        label={t("pno.codePostal")}
        type={"zipCode"}
        onChange={onChange}
      />
      <Champ
        value={adresse.city}
        label={t("pno.ville")}
        type={"city"}
        onChange={onChange}
      />
      <Button color="secondary" onClick={changerEnModeAuto}>
        {t("pno.rechercherAdresse")}
      </Button>
    </section>
  );
};
