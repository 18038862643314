import React from "react";

import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import theme from "@/theme";

interface BlocTarifProps {
  montantAnnuelAvecTA: number;
  montantTaxeAttentat: number;
}
export const BlocTarif: React.FC<BlocTarifProps> = ({
  montantAnnuelAvecTA,
  montantTaxeAttentat,
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      style={{
        padding: 15,
        borderRadius: 10,
        backgroundColor: theme.palette.primary.main,
        height: "100%",
      }}
    >
      <Typography style={{ color: "white" }} variant="h3" align="center">
        {t("pno.recapitulatif.tarif")}
      </Typography>

      <Typography
        style={{ color: "white", padding: 10 }}
        variant="h4"
        align="center"
      >
        {montantAnnuelAvecTA}{" "}
        <em style={{ fontSize: 15 }}>{t("pno.recapitulatif.euroParAn")}</em>
      </Typography>

      <Typography style={{ color: "white" }} variant="body1" align="center">
        {t("pno.recapitulatif.taxAttentat", { taxe: montantTaxeAttentat })}
      </Typography>
    </Grid>
  );
};
