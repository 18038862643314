import React, { ChangeEvent, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";

import { Souscription } from "@/types/Souscription";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";
import adresseApi from "@/api/adresseApi";
import { debounce } from "@material-ui/core";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type Adresse = getTypeOriginalDepuisPartial<
  Souscription["data"]["contractAddress"]
>;

type AdresseSuggeree = {
  label: string;
  housenumber: string;
  name: string;
  postcode: string;
  citycode: string;
  city: string;
  street: string;
};

interface AdresseAutoProps {
  changerEnModeManuel: () => void;
}
export const AdresseAuto = ({ changerEnModeManuel }: AdresseAutoProps) => {
  const DEBOUNCE_TIME = 500;

  const { dataSouscription, setDataSouscription } = useContext(
    PnoSouscriptionContexte
  );
  const { t } = useTranslation();
  const [loading, setIsLoading] = useState(false);
  const [adressesSuggerees, setAdressesSuggerees] = useState<AdresseSuggeree[]>(
    []
  );

  const buttonOption = (
    <Button fullWidth onMouseDown={changerEnModeManuel}>
      <Typography variant="body1">{t("pno.saisirManuellement")}</Typography>
    </Button>
  );

  const getSuggestionsDadresses = useMemo(
    () =>
      debounce(async (adresseRecherchee) => {
        const { length } = adresseRecherchee;
        if (length > 4) {
          const adresseSuggerees = await adresseApi.getSuggestionAdresses(
            adresseRecherchee
          );
          setAdressesSuggerees(adresseSuggerees);
        } else {
          setAdressesSuggerees([]);
        }
      }, DEBOUNCE_TIME),
    []
  );

  const handleAdresseChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    getSuggestionsDadresses(e.target.value);
    setIsLoading(false);
  };

  const handleSelectAdresse = (adresseSelectionnee: AdresseSuggeree) => {
    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        contractAddress: {
          address1: adresseSelectionnee.name,
          address2: "",
          zipCode: adresseSelectionnee.citycode,
          city: adresseSelectionnee.city,
        } as Adresse,
      },
    });

    changerEnModeManuel();
  };

  return (
    <div>
      <Autocomplete<AdresseSuggeree>
        getOptionLabel={(option) => option.label || "-"}
        options={adressesSuggerees}
        noOptionsText={buttonOption}
        loadingText={t("common.loadingText")}
        loading={loading}
        filterOptions={() => adressesSuggerees}
        onInputChange={(e) =>
          handleAdresseChange(e as ChangeEvent<HTMLInputElement>)
        }
        onChange={(e, adresseSelectionnee) => {
          if (adresseSelectionnee) handleSelectAdresse(adresseSelectionnee);
        }}
        renderInput={(params) => (
          <TextField {...params} label={t("pno.adresse")} variant="outlined" />
        )}
        style={{ width: "65%" }}
      />
    </div>
  );
};
