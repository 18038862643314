import React, { useContext } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { ASSURIMO_LEGAL_MANSIONS_URL } from "@/constants/assurimo";

import { UserContext } from "@/contexts/user";

export const Confirmations = () => {
  // @ts-ignore
  const [userState] = useContext(UserContext);
  const { t } = useTranslation();

  const nomAgence = userState?.profile?.code || "-";

  const AuthenticiteInformation = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox id={"authenticite"} required />
      <Typography
        style={{ fontSize: 14 }}
        component={"label"}
        htmlFor={"authenticite"}
      >
        {t("pno.recapitulatif.confirmations.authenticite", { nomAgence })}
      </Typography>
    </div>
  );

  const ConditionsGenerales = () => (
    <a
      href="/files/Conditions_générales_PNO.pdf"
      download="Conditions_générales.pdf"
      style={{
        color: "inherit",
        fontWeight: "bold",
        textDecoration: "underline",
        cursor: "pointer",
      }}
    >
      Conditions Générales
    </a>
  );

  const IPID = () => (
    <a
      href="/files/IPID_PNO.pdf"
      download="Fiche_IPID.pdf"
      style={{
        color: "inherit",
        fontWeight: "bold",
        textDecoration: "underline",
        cursor: "pointer",
      }}
    >
      la Fiche IPID
    </a>
  );

  const MentionsLegales = () => (
    <a
      href={ASSURIMO_LEGAL_MANSIONS_URL}
      style={{
        color: "inherit",
        fontWeight: "bold",
        textDecoration: "underline",
        cursor: "pointer",
      }}
      target="_blank" rel="noopener noreferrer"
    >
      Mentions Légales
    </a>
  );

  const AccordPriseConnaissanceDesConditions = () => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox id={"acceptConditions"} required />
      <Typography
        style={{ fontSize: 14 }}
        component={"label"}
        htmlFor={"acceptConditions"}
      >
        <Trans i18nKey="pno.recapitulatif.confirmations.conditions">
          <ConditionsGenerales />
          <IPID />
          <MentionsLegales />
        </Trans>
      </Typography>
    </div>
  );

  return (
    <div>
      <AuthenticiteInformation />
      <br />
      <AccordPriseConnaissanceDesConditions />
    </div>
  );
};
