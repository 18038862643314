import React, { ChangeEvent, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Souscription } from "@/types/Souscription";
import { PnoSouscriptionContexte } from "@/views/PnoSouscription";

import { Champ } from "@/views/PnoSouscription/FormulaireElements/Champ";
import { Checkbox, FormControlLabel } from "@material-ui/core";

type getTypeOriginalDepuisPartial<T> = T extends Partial<infer U> ? U : never;
type DataSouscription = getTypeOriginalDepuisPartial<Souscription["data"]>;
type Adresse = getTypeOriginalDepuisPartial<
  Souscription["data"]["contractAddress"]
>;
export const Appartement = () => {
  const SURFACE_MAX: number = 150;

  const { dataSouscription, setDataSouscription } = useContext(
    PnoSouscriptionContexte
  );
  const { t } = useTranslation();
  const adresse: Partial<Adresse> =
    dataSouscription.data?.contractAddress || {};

  const onChangeAdresse = (
    e: ChangeEvent<HTMLInputElement>,
    type: keyof Adresse
  ) => {
    let oldData = { ...adresse };
    let newData = { ...oldData, [type]: e.target.value };

    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        contractAddress: newData as Adresse,
      },
    });
  };
  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    type: keyof DataSouscription
  ) => {
    const newData = e.target.value || e.target.checked;
    setDataSouscription({
      ...dataSouscription,
      data: {
        ...dataSouscription.data,
        [type]: newData,
      },
    });
  };

  return (
    <>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!dataSouscription.data?.furnished}
              onChange={(e) => onChange(e, "furnished")}
              color="secondary"
            />
          }
          label="Meublé"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={!!dataSouscription.data?.monument}
              onChange={(e) => onChange(e, "monument")}
              color="secondary"
            />
          }
          label="Monument historique"
        />
      </div>
      <div>
        <Champ
          value={dataSouscription.data?.area}
          label={t("pno.surfaceBien", { surfaceMax: SURFACE_MAX })}
          type={"area"}
          onChange={onChange}
          dataType={"number"}
          max={SURFACE_MAX}
          min={0}
        />
        <Champ
          value={dataSouscription.data?.floor}
          label={t("pno.etage")}
          type={"floor"}
          onChange={onChange}
          dataType={"number"}
          required={false}
          min={0}
        />
      </div>
      <div>
        <Champ
          value={adresse.residency}
          label={t("pno.residence")}
          type={"residency"}
          onChange={onChangeAdresse}
        />
        <Champ
          value={adresse.building}
          label={t("pno.batiment")}
          type={"building"}
          onChange={onChangeAdresse}
          required={false}
        />
      </div>
      <div>
        <Champ
          value={adresse.lotNumber}
          label={t("pno.numLotFoncia")}
          type={"lotNumber"}
          onChange={onChangeAdresse}
        />
        <Champ
          value={adresse.buildingNumber}
          label={t("pno.numBatimentFoncia")}
          type={"buildingNumber"}
          onChange={onChangeAdresse}
        />
      </div>
    </>
  );
};
