import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
    cursor: "pointer",
    color: theme.palette.secondary.main,
  },
  secondIcon: {
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
    cursor: "unset",
    marginLeft: theme.spacing(1),
    fontSize: "15px",
  },
  subtitle: {
    color: theme.palette.secondary.main,
    fontWeight: 400,
    fontSize: "1.25rem",
  },
}));

export default useStyles;
